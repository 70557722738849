/**
 * @module Utils/validate
 * */

import i18n from '@/plugins/i18n'
import moment from 'moment'

const DATE = 'DD.MM.YYYY'

/**
 * Правило валидации
 * @callback ValidateRule
 * @global
 * @param {string} val
 * @return {boolean}
 * @return {string} - Сообщение об ошибке
 * */

/**
 * Простая функция валидации
 * @callback SimpleValidateFunc
 * @global
 * @param {string} val
 * @return {boolean}
 * */

/**
 * Парсер строковых шаблонов
 * @function
 * @param {String} string - Строковой шаблон
 * @param {...String} rest - Подставляемые значения
 * @return {String}
 * */
export function stringTemplateParser (string, ...rest) {
  return i18n.t(`errors.validate.${string}`, rest)
}

/**
 * Валидация email
 * @type {SimpleValidateFunc}
 * */
export const email = val => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val)

/**
 * Валидация номера телефона в фоомате E164
 * @type {SimpleValidateFunc}
 * */
export const phone = val => /^\+?[1-9]\d{10}$/.test(val)

/**
 * Валидация номера телефона в страховом событии
 * Нужна для корректной работы при подтягивании данных
 * @type {SimpleValidateFunc}
 */
export const phoneUnmasked = val => /^\d{10}$/.test(val)

/**
 * Валидация номера телефона в фоомате +7(123) 123-12-31
 * @type {SimpleValidateFunc}
 * */
export const phoneMasked = val => /^(\+7)?\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/.test(val)

/**
 * Валидация обязательного поля
 * @type {SimpleValidateFunc}
 * */
export const required = val => {
  if (typeof val === 'string') return !!(val.trim())
  return !!val
}

/**
 * Валидация кирилица и пробелы
 * @type {SimpleValidateFunc}
 * */
export const cyrillicAndSpace = val => /^[а-яА-Я ]*$/.test(val)

/**
 * Валидация только цифры
 * @type {SimpleValidateFunc}
 * */
export const digit = val => /^\d+$/.test(val)

/**
 * Валидация только буквенно-цифровое содержимое
 * @function
 * @param {String} val
 * @return {Boolean}
 * */
export const alphanumeric = val => /^[0-9a-zA-Zа-яА-я]*$/.test(val)

/**
 * Валидация на количество слов в строке
 * определяется пробелами между символами
 * @function
 * @param {String} val
 * @param {Number} count
 * @return {Boolean}
 * */
export const wordsCount = (val, count) => {
  const spaces = val.trim().match(/\s+/g)
  return spaces?.length === count - 1
}

/**
 * Валидация на количество символов в строке
 * @function
 * @param {String} val
 * @param {Number} min=0
 * @param {Number} [max]
 * @return {Boolean}
 * */
export const stringLengthRange = (val, min = 0, max) => (
  val.length >= min && (!max || val.length <= max)
)

/**
 * Количество букв в строке
 * @function
 * @param {String} val
 * @param {Number} min=0
 * @param {Number} [max]
 * @return {Boolean}
 * */
export const lettersCountInStringRange = (val, min = 0, max) => {
  const lettersCount = val.split('')
    .reduce((acc, nextVal) => (/[A-zА-я]/.test(nextVal) ? ++acc : acc), 0)

  return lettersCount >= min && (!max || lettersCount <= max)
}

/**
 * Валидация на совершеннолетие
 *
 * С переданной даты на текущий момент должно пройти 18 лет + 1 день
 * @function
 * @param {String} val
 * @param {String} format
 * @return {Boolean}
 * */
export const majority = (val, format) => {
  const minMajorityDate = moment().startOf('day').utc(true)
    .subtract(18, 'years')
  const birthDate = moment(val, format).startOf('day').utc(true)
  if (!birthDate.isValid()) throw new Error('Invalid date')

  return birthDate.isBefore(minMajorityDate)
}

/**
 * Валидация строки с датой
 *
 * @function
 * @param {String} val
 * @param {String} format
 * @param {RegExp} regex
 * @return {Boolean}
 * */
export const date = (val, format = DATE, regex = /^\d{2}.\d{2}.\d{4}/) => (
  regex.test(val) && moment(val, format).isValid()
)

/**
 * Цифры и большие буквы
 * @function
 * @param {String} val
 * @return {Boolean}
 * */
export const digitsAndLatinCapsLetters = (val) => /^[0-9A-Z]*$/.test(val)

/**
 * Правило валидации для email
 * @type ValidateRule
 * */
export const emailRule = val => !val || email(val) || i18n.t('errors.validate.EMAIL')

/**
 * Правило валидации обязательного поля
 * @type ValidateRule
 * */
export const requiredRule = val => required(val) || i18n.t('errors.validate.REQUIRED')

/**
 * Правило валидации для номера телефона
 * @type ValidateRule
 * */
export const phoneRule = val => !val || phone(val) || i18n.t('errors.validate.PHONE')

/**
 * Правило валидации для номера телефона без маски и без кода страны
 * @type ValidateRule
 */
export const phoneRuleUnmasked = val => !val || phoneUnmasked(val) || i18n.t('errors.validate.PHONE')

/**
 * Правило валидации для номера телефона с маской
 * @type ValidateRule
 * */
export const phoneRuleMasked = val => !val || phoneMasked(val) || i18n.t('errors.validate.PHONE')

/**
 * Правило валидации кирилица и пробелы
 * @type ValidateRule
 * */
export const cyrillicAndSpaceRule = val => (
  !val || cyrillicAndSpace(val) || i18n.t('errors.validate.CYRILLIC_AND_SPACE')
)

/**
 * Правило валидации в строке только цифры
 * @function
 * @type ValidateRule
 * */
export const digitRule = val => !val || digit(val) || i18n.t('errors.validate.DIGIT')

/**
 * Правило валидации в строке только буквенно-цифровое содержимое
 * @type ValidateRule
 * */
export const alphanumericRule = val => !val || alphanumeric(val) || i18n.t('errors.validate.ALPHANUMERIC')

/**
 * Правило валидвции Цифры и большие буквы
 * @function
 * @param {String} val
 * @return {Boolean}
 * */
export const digitsAndLatinCapsLettersRule = (val) => (
  !val || digitsAndLatinCapsLetters(val) || i18n.t('errors.validate.DIGITS_AND_LATIN_CAPS_LETTERS')
)

/**
 * Правило валидации на совершеннолетие
 * С переданной даты на текущий момент должно пройти 18 лет + 1 день
 * @type ValidateRule
 * */
export const majorityRule = val => !val || majority(val) || i18n.t('errors.validate.MAJORITY')

/**
 * Фабрика возвращающая Правило валидации на совершеннолетие
 * С переданной даты на текущий момент должно пройти 18 лет + 1 день
 * @type ValidateRule
 * */
export const majorityRuleFabric = format =>
  val => !val || majority(val, format) || i18n.t('errors.validate.MAJORITY')

/**
 * Фабрика возвращающая Правило валидации на количество символов в строке
 * @function
 * @param {Number} min=0
 * @param {Number} [max]
 * @return {ValidateRule}
 * */
export function stringLengthRangeRuleFabric (min = 0, max) {
  return val => (
    !val ||
    stringLengthRange(val, min, max) ||
    (max && min && min === max && stringTemplateParser('RANGE_EXACT', min)) ||
    (max && min && stringTemplateParser('RANGE_MIN_MAX', min, max)) ||
    (min && stringTemplateParser('RANGE_MIN', min)) ||
    (max && stringTemplateParser('RANGE_MAX', max))
  )
}

/**
 * Фабрика возвращающая Правило валидации на количество букв в строке
 * @function
 * @param {Number} min=0
 * @param {Number} [max]
 * @return {ValidateRule}
 * */
export function lettersCountInStringRangeRuleFabric (min = 0, max) {
  return val => (
    !val ||
    lettersCountInStringRange(val, min, max) ||
    (max && min && min === max && stringTemplateParser('LETTERS_RANGE_EXACT', min)) ||
    (max && min && stringTemplateParser('LETTERS_RANGE_MIN_MAX', min, max)) ||
    (min && stringTemplateParser('LETTERS_RANGE_MIN', min)) ||
    (max && stringTemplateParser('LETTERS_RANGE_MAX', max))
  )
}

/**
 * Фабрика возвращающая Правило валидации на количество символов в строке
 * @function
 * @param {number} wordsCountArg
 * @return {ValidateRule} - Правило валидации на количество символов в строке
 * */
export function wordsCountRuleFabric (wordsCountArg) {
  return val => (
    !val ||
    wordsCount(val, wordsCountArg) ||
    stringTemplateParser('WORDS_COUNT', wordsCountArg)
  )
}

/**
 * Правило валидация строки с датой
 * @function
 * @param {string} val
 * @param {string} format
 * @param {RegExp} regex
 * @return {Boolean}
 * */
export const dateRule = (val, format = DATE, regex = /^\d{2}.\d{2}.\d{4}/) => (
  !val || date(val, format, regex) || i18n.t('errors.validate.INVALID_DATE')
)
